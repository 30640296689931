import React, { useState, useEffect } from 'react';
import StationMap from './StationMap';
import axios from 'axios';
import './App.css';
import { ClipLoader } from 'react-spinners';

function App() {
    const [evStations, setEvStations] = useState([]); // State to store EV station data
    const [searchOptions, setSearchOptions] = useState({ connectorType: ['CHADEMO'], maxDistance: 0.25 }); // State to store search options
    const [mapCenter, setMapCenter] = useState({ lat: 38.9072, lon: -77.0369, radius: 50 }); // State to store map center and radius
    const [isLoading, setIsLoading] = useState(false);

    const fetchStations = async () => {
        try {
            setIsLoading(true);
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
            const response = await axios.get(`${backendUrl}${endpoint}`, {
                params: {
                    connector_type: searchOptions.connectorType.join(','),
                    max_comparison_dist: searchOptions.maxDistance,
                    lat: mapCenter.lat,
                    lon: mapCenter.lon,
                    search_radius: mapCenter.radius
                }
            });
            setEvStations(response.data);
            setIsLoading(false);
            console.log('---')
            console.log(searchOptions);
            console.log('', response.data.length, 'stations');
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchStations();
    }, [searchOptions, mapCenter]);

    const connectorNameMap = {
        "all": "All",
        "CHADEMO": "CHAdeMO",
        "J1772COMBO": "CCS",
        "TESLA": "Tesla",
        "J1772": "J1772",
        "NEMA1450": "NEMA 14-50",
        "NEMA515": "NEMA 5-15",
        "NEMA520": "NEMA 5-20",
    };


    const getButtonText = () => {
        if (searchOptions.connectorType.length === 0) {
            return "Select Connector Type";
        }
        return `Connector: ${searchOptions.connectorType.map(type => connectorNameMap[type]).join(', ')}`;
    };

    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const handleConnectorChange = (event) => {
        if (event.target.checked) {
            const newConnectorType = [...searchOptions.connectorType, event.target.value];
            setSearchOptions(prevOptions => ({
                ...prevOptions,
                connectorType: newConnectorType
            }));
        } else {
            const newConnectorType = searchOptions.connectorType.filter(type => type !== event.target.value);
            setSearchOptions(prevOptions => ({
                ...prevOptions,
                connectorType: newConnectorType
            }));
        };
    };

    const handleDistanceChange = (event) => {
        setSearchOptions(prevOptions => ({
            ...prevOptions,
            maxDistance: event.target.value
        }));
    };

    return (
        <div className="App">
            <div className="loadingSpinner">
                {isLoading ? <ClipLoader /> : null}
            </div>
            <form>
                <div className="dropdown">
                    <button type="button" onClick={toggleDropdown}>
                        {getButtonText()}
                    </button>
                    {showDropdown && (
                        <div className="dropdown-content">
                            {Object.entries(connectorNameMap).map(([apiName, displayName]) => (
                                <label key={apiName}>
                                    <input
                                        type="checkbox"
                                        value={apiName}
                                        checked={searchOptions.connectorType.includes(apiName)}
                                        onChange={handleConnectorChange}
                                    />
                                    {displayName}
                                </label>
                            ))}
                        </div>
                    )}
                </div>
                <label>
                    
                    Max walking dist.:
                    <select
                        type="number"
                        id="maxDistance"
                        name="maxDistance"
                        value={searchOptions.maxDistance}
                        onChange={handleDistanceChange}
                    >
                        <option value="0.1">0.1 mi</option>
                        <option value="0.25">0.25 mi</option>
                        <option value="0.5">0.5 mi</option>
                        <option value="1">1 mi</option>
                    </select>


                </label>
            </form>

            <StationMap evStations={evStations} onCenterChanged={setMapCenter} connectorNameMap={connectorNameMap} />
        </div>
    );
}

export default App;

// todos:
// style the options
// make the pins more attractive and clear which is pinball and chargers
// improve pin pop-ups
// only add pins, never subtract them
// deployment: vercel is good at the react side but i haven't figured out how to get flask working on it or elsewhere
import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';


const DEFAULT_LAT = 38.9377754;
const DEFAULT_LON = -77.0330355;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const StationMap = ({ evStations, onCenterChanged, connectorNameMap }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markers = useRef([]); // Ref to store marker instances
    const lines = useRef([]); // Ref to store line instances


    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [DEFAULT_LON, DEFAULT_LAT],
            zoom: 9,
            minZoom: 4
        });

        map.current.on('moveend', () => {
            const center = map.current.getCenter();
            // console.log(`Latitude: ${center.lat}, Longitude: ${center.lng}`); // this is logging every time

            const bounds = map.current.getBounds();


            // Create points for the west and east boundaries
            const northPoint = turf.point([bounds.getNorth(), bounds.getCenter().lat]);
            const southPoint = turf.point([bounds.getSouth(), bounds.getCenter().lat]);

            const westPoint = turf.point([bounds.getWest(), bounds.getCenter().lat]);
            const eastPoint = turf.point([bounds.getEast(), bounds.getCenter().lat]);

            // Calculate the distance between the west and east boundaries in kilometers
            const heightKm = turf.distance(northPoint, southPoint);
            const widthKm = turf.distance(westPoint, eastPoint);

            // get radius of smallest circle that can contain the map, using Pythagorean theorem
            const radiusKm = Math.sqrt(Math.pow(heightKm, 2) + Math.pow(widthKm, 2)) / 2;


            // Convert the distance to miles
            const radiusMiles = radiusKm * 0.621371;


            onCenterChanged({ lat: center.lat, lon: center.lng, radius: radiusMiles });

        });



    }, []);

    useEffect(() => {

        if (!map.current) return;

        // Remove existing markers
        markers.current.forEach(marker => marker.remove());
        markers.current = [];

        lines.current.forEach(lineId => {
            if (map.current.getLayer(lineId)) {
                map.current.removeLayer(lineId);
                map.current.removeSource(lineId); // Important: Also remove the source
            }
        });
        lines.current = []; // Reset the array
    

        function addToggleListener() {
            const toggle = document.querySelector('.pinball-machines-toggle');
            const arrow = toggle.querySelector('.arrow-icon');
            const machineList = document.querySelector('.pinball-machine-names-list');
        
            toggle.addEventListener('click', () => {
                const isExpanded = machineList.style.display === 'block';
                machineList.style.display = isExpanded ? 'none' : 'block';
                arrow.classList.toggle('down', !isExpanded);
            });
        }

        // Add new markers
        evStations.forEach(charger => {
            const chargerPopup = new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                    `<h1><a href="https://afdc.energy.gov/stations/#/station/${charger.id}" target="_blank">
                    ${charger.station_name}
                    </a></h1>
                    <a href="https://www.google.com/maps?q=${encodeURIComponent(charger.street_address + ', ' + charger.city + ', ' + charger.state)}" target="_blank">
                    <p>${charger.street_address}</p>
                     <p>${charger.city}, ${charger.state}</p></a>
                     <ul class="charger-connector-list">
                        ${charger.ev_connector_types.map(type => `<li class="charger-connector">${connectorNameMap[type]}</li>`).join('')}
                    </ul>
                     <p class="pinball-machines-toggle"><span class="arrow-icon"></span>${charger.total_pinball_machines} machines</p>
                     <ul class="pinball-machine-names-list" style="display: none;">
                         ${charger.nearby_pinball_machines.map(name => `<li class="pinball-machine-name">${name}</li>`).join('')}
                     </ul>`

                );

            chargerPopup.on('open', addToggleListener);

            const evStationCoords = [charger.longitude, charger.latitude];


            // Add markers for nearby pinball locations
            charger.nearby_pinball_locations.forEach(pinballLocation => {
                const pinballLocationPopup = new mapboxgl.Popup({ offset: 25 }) // add popups
                    .setHTML(
                        `<h1><a href="https://pinballmap.com/map/?by_location_id=${pinballLocation.id}" target="_blank">
                        ${pinballLocation.name}
                        </a></h1>
                        <a href="https://www.google.com/maps?q=${encodeURIComponent(pinballLocation.name + ', ' + pinballLocation.street + ', ' + pinballLocation.city + ', ' + pinballLocation.state)}" target="_blank">
                         <p>${pinballLocation.street}</p>
                         <p>${pinballLocation.city}, ${pinballLocation.state}</p>
                         </a>
                         <p class="pinball-machines-toggle"><span class="arrow-icon"></span>${pinballLocation.num_machines} machines</p>
                         <ul class="pinball-machine-names-list" style="display: none;">
                             ${pinballLocation.machine_names.map(name => `<li class="pinball-machine-name">${name}</li>`).join('')}
                         </ul>`
                    );
                
                    pinballLocationPopup.on('open', addToggleListener);
                    


                const pinballMarker = new mapboxgl.Marker({
                    element: document.createElement('div') // create a new HTML element for the marker
                })
                    .setLngLat([pinballLocation.lon, pinballLocation.lat])
                    .setPopup(pinballLocationPopup) // sets the popup
                    .addTo(map.current);

                // Set the marker's content to the number of pinball machines
                pinballMarker.getElement().innerHTML = `<div class="pinball-marker map-marker">🪩${pinballLocation.num_machines}🪩</div>`;

                pinballMarker.getElement().addEventListener('click', () => {
                    if (map.current.getZoom() < 12) {
                        map.current.flyTo({
                            center: [pinballLocation.longitude, pinballLocation.latitude],
                            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                            zoom: 15
                        });
                    };
                });
    

                markers.current.push(pinballMarker); // Store the marker

                const pinballCoords = [pinballLocation.lon, pinballLocation.lat];

                const lineId = `line-${charger.station_name}-${pinballLocation.name}`;

                const line = {
                    'id': lineId,
                    'type': 'line',
                    'source': {
                        'type': 'geojson',
                        'data': {
                            'type': 'Feature',
                            'properties': {},
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': [evStationCoords, pinballCoords]
                            }
                        }
                    },
                    'paint': {
                        'line-color': '#888', // You can choose a color
                        'line-width': 3
                    }
                }

                map.current.addLayer(line);
                lines.current.push(lineId);

            });

            const chargerMarker = new mapboxgl.Marker({
                element: document.createElement('div') // create a new HTML element for the marker
            })
                .setLngLat([charger.longitude, charger.latitude])
                .setPopup(chargerPopup) // sets the popup
                .addTo(map.current);

            // Set the marker's content to the number of pinball machines
            chargerMarker.getElement().innerHTML = `<div class="charger-marker map-marker">⚡️${charger.total_pinball_machines}⚡️</div>`;
            
            chargerMarker.getElement().addEventListener('click', () => {
                if (map.current.getZoom() < 12) {
                    map.current.flyTo({
                        center: [charger.longitude, charger.latitude],
                        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                        zoom: 15
                    });
                };
            });

            markers.current.push(chargerMarker); // Store the marker


        });




    }, [evStations]);
    return <div className="map" ref={mapContainer} />;
};

export default StationMap;
